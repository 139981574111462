import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeExhibit: {
      title: null,
      year: null,
      gallery: null,
      location: null,
    },
    exhibition: {
      thumb: null,
      info: [],
      truncate: null,
    },
    exhibit: {
      exhibition: null,
      year: null,
      gallery: null,
      location: null,
      title: null,
      medium: null,
      dimensionsIMP: null,
      dimensionsMET: null,
      thumb: null,
      info: [],
      truncate: null,
    },
    exhibits: [],
    mural: {
      title: null,
      location: null,
      year: null,
      info: [],
      truncate: null,
      thumb: null,
    },
    video: null,
  },
  getters: {
    activeExhibit: (state) => {
      return state.activeExhibit;
    },
    exhibition: (state) => {
      return state.exhibition;
    },
    exhibit: (state) => {
      return state.exhibit;
    },
    exhibits: (state) => {
      return state.exhibits;
    },
    mural: (state) => {
      return state.mural;
    },
    video: (state) => {
      return state.video;
    },
  },
  mutations: {
    setActiveExhibit(state, exhibit) {
      state.activeExhibit = {
        title: exhibit.title,
        year: exhibit.year,
        gallery: exhibit.gallery,
        location: exhibit.location,
      };
    },
    setExhibition(state, exhibition) {
      state.exhibition = {
        thumb: exhibition.thumb,
        info: exhibition.info,
        truncate: exhibition.truncate,
      };
    },
    setExhibit(state, exhibit) {
      state.exhibit = {
        exhibition: exhibit.exhibition,
        year: exhibit.year,
        gallery: exhibit.gallery,
        location: exhibit.location,
        title: exhibit.title,
        medium: exhibit.medium,
        thumb: exhibit.thumb,
        dimensionsIMP: exhibit.dimensionsIMP,
        dimensionsMET: exhibit.dimensionsMET,
        info: exhibit.info,
        truncate: exhibit.truncate,
      };
    },
    setExhibits(state, exhibits) {
      state.exhibits = exhibits;
    },
    setMural(state, mural) {
      state.mural = {
        title: mural.title,
        location: mural.location,
        year: mural.year,
        info: mural.info,
        truncate: mural.truncate,
        thumb: mural.thumb,
      };
    },
    setVideo(state, video) {
      state.video = video;
    },
  },
  actions: {
    setActiveExhibit({ commit }, exhibit) {
      commit("setActiveExhibit", exhibit);
    },
    setExhibition({ commit }, exhibition) {
      commit("setExhibition", exhibition);
    },
    setExhibit({ commit }, exhibit) {
      commit("setExhibit", exhibit);
    },
    setExhibits({ commit }, exhibits) {
      commit("setExhibits", exhibits);
    },
    setMural({ commit }, mural) {
      commit("setMural", mural);
    },
    setVideo({ commit }, video) {
      commit("setVideo", video);
    },
  },
});
