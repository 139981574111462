import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { Inkline } from "@inkline/inkline/src";
// import * as components from "@inkline/inkline/src/components";
// import "@inkline/inkline/src/inkline.scss";
// import { Inkline, components } from '@inkline/inkline';
// import '@inkline/inkline/inkline.scss';
import { Inkline } from "@inkline/inkline/src";
import * as components from "@inkline/inkline/src/components";
import "@inkline/inkline/src/inkline.scss";

import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";
import { VueMasonryPlugin } from "vue-masonry";
const accessToken = "";
const endpoint = "https://bockhaus.cdn.prismic.io/api/v2";

Vue.use(Inkline, {
  components,
  config: {
    variant: "light",
  },
});

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

Vue.use(VueMasonryPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
