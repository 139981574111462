<template>
  <div>
    <div
      v-if="isHome"
      class="
        _width-100
        _vh-100
        _display-flex
        _justify-content-center
        _align-items-center
        bock--background
      "
    >
    <div class="_position-absolute _vw-100 _vh-100">
      <video ref="videoPlayer" class="video-js _position-absolute _vw-100 _vh-100"></video>
    </div>

      <!-- <div class="_position-absolute _vw-100">
        <div class="_embed _embed-16by9"> -->
          <!-- <iframe
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            width="1920"
            height="1080"
            type="text/html"
            :src="video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> -->
          <!-- <video ref="videoPlayer" class="video-js"></video> -->
        <!-- </div>
      </div> -->
      <div
        class="
          _position-absolute
          bock--ui-header
          _margin-top-1 _margin-left-1
          _padding-right-2
        "
      >
        <div class="_display-flex bock--logo--nav bock--light">
          <div class="bock--logo _flex-grow-1">
            <router-link :to="{ name: 'Home' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 498 341"
                class="bock--logo _margin-left-1-2"
              >
                <path
                  d="M404.21 219.904c.016-21.103-17.58-38.088-39.471-38.107-21.85-.019-39.591 17.037-39.605 38.077-.015 21.034 17.703 38.093 39.574 38.105 21.879.011 39.489-16.963 39.503-38.075M319.495 85.18c.066 20.993 17.913 37.971 39.81 37.872 21.798-.1 39.494-17.293 39.427-38.307-.067-20.898-18.037-37.97-39.863-37.871-21.867.1-39.44 17.195-39.374 38.306m-66.213-1.719c.059-20.953-17.766-38.226-39.522-38.297-21.914-.07-39.677 16.85-39.77 37.885-.093 21.086 17.519 38.223 39.358 38.296 21.8.072 39.875-17.074 39.934-37.884m244.594 231.407l-233.133 1.148-13.365 25.192-88.833-.235 82.52-148.674-40.313.547-104.526 148.61-100.492-.113 150.66-192.08C103.322 124.153 96.08 64.17 131.578 28.647L79.732 1.322 368.31.252c41.134 0 97.134 22.748 98.038 82.317-.015 48.875-44.461 66.593-46.832 67.83 30.989 8.684 68.15 25.49 72.595 77.045-.59 45.778-29.718 61.662-29.718 61.662"
                  fill-rule="evenodd"
                />
              </svg>
            </router-link>
          </div>
          <div class="bock--main _margin-bottom-xs-0 _margin-bottom-md-4">
            <i-nav vertical size="sm" class="bock--main-nav">
              <i-nav-item :to="{ name: 'Exhibitions' }"
                ><span>Work</span></i-nav-item
              >
              <i-nav-item :to="{ name: 'Videos' }"
                ><span>Videos</span></i-nav-item
              >
              <i-nav-item :to="{ name: 'Murals' }"
                ><span>Murals</span></i-nav-item
              >
              <i-nav-item :to="{ name: 'Press' }"
                ><span>Press</span></i-nav-item
              >
              <i-nav-item :to="{ name: 'About' }"
                ><span>About</span></i-nav-item
              >
              <i-nav-item href="mailto:bockhaus.management@gmail.com"
                ><span>Contact</span></i-nav-item
              >
              <i-nav-item
                href="http://www.instagram.com/bockhaus"
                target="_blank"
              >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="bockgram" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                </span>
              </i-nav-item>
            </i-nav>
          </div>
        </div>
      </div>
    </div>
    <i-row v-else>
      <i-column xs="12" sm="12" md="3" lg="2" xl="2" class="_overflow-auto">
        <div
          class="
            _padding-left-1 _padding-top-1 _padding-right-1 _padding-bottom-1
            _display-flex
            _flex-direction-md-column
            bock--sidebar
          "
        >
          <div class="_display-flex bock--logo--nav">
            <div class="bock--logo _flex-grow-1">
              <router-link :to="{ name: 'Home' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 498 341"
                  class="bock--logo _margin-left-1-2"
                >
                  <path
                    d="M404.21 219.904c.016-21.103-17.58-38.088-39.471-38.107-21.85-.019-39.591 17.037-39.605 38.077-.015 21.034 17.703 38.093 39.574 38.105 21.879.011 39.489-16.963 39.503-38.075M319.495 85.18c.066 20.993 17.913 37.971 39.81 37.872 21.798-.1 39.494-17.293 39.427-38.307-.067-20.898-18.037-37.97-39.863-37.871-21.867.1-39.44 17.195-39.374 38.306m-66.213-1.719c.059-20.953-17.766-38.226-39.522-38.297-21.914-.07-39.677 16.85-39.77 37.885-.093 21.086 17.519 38.223 39.358 38.296 21.8.072 39.875-17.074 39.934-37.884m244.594 231.407l-233.133 1.148-13.365 25.192-88.833-.235 82.52-148.674-40.313.547-104.526 148.61-100.492-.113 150.66-192.08C103.322 124.153 96.08 64.17 131.578 28.647L79.732 1.322 368.31.252c41.134 0 97.134 22.748 98.038 82.317-.015 48.875-44.461 66.593-46.832 67.83 30.989 8.684 68.15 25.49 72.595 77.045-.59 45.778-29.718 61.662-29.718 61.662"
                    fill="#000"
                    fill-rule="evenodd"
                  />
                </svg>
              </router-link>
            </div>
            <div class="bock--main _margin-bottom-xs-0 _margin-bottom-md-4">
              <i-nav vertical size="sm" class="bock--main-nav">
                <i-nav-item :to="{ name: 'Exhibitions' }"
                  ><span>Work</span></i-nav-item
                >
                <i-nav-item :to="{ name: 'Videos' }"
                  ><span>Videos</span></i-nav-item
                >
                <i-nav-item :to="{ name: 'Murals' }"
                  ><span>Murals</span></i-nav-item
                >
                <i-nav-item :to="{ name: 'Press' }"
                  ><span>Press</span></i-nav-item
                >
                <i-nav-item :to="{ name: 'About' }"
                  ><span>About</span></i-nav-item
                >
                <i-nav-item href="mailto:bockhaus.management@gmail.com"
                  ><span>Contact</span></i-nav-item
                >
                <i-nav-item
                  href="http://www.instagram.com/bockhaus"
                  target="_blank"
                >
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                  </span>
                </i-nav-item>
              </i-nav>
            </div>
          </div>
          <div class="_display-md-flex _flex-grow-1 _flex-direction-column">
            <p
              v-if="isExhibition || isExhibit"
              class="bock--block"
              ref="bockBlock"
            >
              <span
                v-if="isExhibition"
                class="_font-weight-normal _margin-bottom-0 bock--title"
              >
                {{ activeExhibit.title }}
              </span>
              <span
                v-else-if="isExhibit"
                class="
                  _font-weight-normal
                  _margin-bottom-0
                  bock--title bock--hover
                "
                @click="backToExhibition"
              >
                {{ activeExhibit.title }}
              </span>
              <span
                v-if="activeExhibit.gallery"
                class="
                  _margin-top-0 _margin-bottom-0
                  _display-block
                  _text-muted
                  bock--desc
                "
              >
                {{ activeExhibit.gallery }}
              </span>
              <span class="_display-block _text-muted bock--desc">
                <span v-if="activeExhibit.location">{{
                  activeExhibit.location
                }}</span
                ><span v-if="activeExhibit.year"
                  >, {{ activeExhibit.year }}</span
                >
              </span>
            </p>
            <div
              v-if="isExhibition"
              class="bock--info bock--hover"
              @click="showExhibitionInfo(exhibition.info)"
            >
              <small v-if="exhibition.truncate" class="_text-muted bock--trunc">
                {{ exhibition.truncate }}
              </small>
            </div>
            <div v-if="isExhibit">
              <p class="bock--block _margin-bottom-1-2">
                <span
                  v-if="exhibit.title"
                  class="
                    _font-weight-semibold
                    _margin-bottom-0
                    _text-muted
                    bock--desc
                  "
                >
                  {{ exhibit.title }}
                </span>
                <span
                  v-if="exhibit.medium"
                  class="_display-block _text-muted bock--desc bock--trunc"
                >
                  {{ exhibit.medium }}
                </span>
                <span
                  v-if="exhibit.dimensionsIMP"
                  class="_display-block _text-muted bock--desc bock--spec"
                >
                  {{ exhibit.dimensionsIMP }}
                </span>
                <span
                  v-if="exhibit.dimensionsMET"
                  class="_display-block _text-muted bock--desc bock--spec"
                >
                  {{ exhibit.dimensionsMET }}
                </span>
              </p>
            </div>
            <div v-if="isMural">
              <p class="bock--block _margin-bottom-1-2">
                <span
                  v-if="mural.title"
                  class="
                    _font-weight-normal
                    _margin-bottom-0
                    _text-muted
                    bock--title
                  "
                >
                  {{ mural.title }}
                </span>
                <span class="_display-block _text-muted bock--desc">
                  <span v-if="mural.location">{{ mural.location }}</span
                  ><span v-if="mural.year">, {{ mural.year }}</span>
                </span>
              </p>
            </div>
            <div
              v-if="isMural"
              class="bock--info bock--hover"
              @click="showMuralInfo(mural.info)"
            >
              <small v-if="mural.truncate" class="_text-muted bock--trunc">
                {{ mural.truncate }}
              </small>
            </div>
            <div
              v-if="isExhibit"
              class="bock--info bock--hover"
              @click="showExhibitInfo(exhibit.info)"
            >
              <small v-if="exhibit.truncate" class="_text-muted bock--trunc">
                {{ exhibit.truncate }}
              </small>
            </div>
          </div>
          <!-- <div
            class="
              _display-xs-none _display-md-flex
              _width-100
              bock--footer-nav
            "
          >
            <i-nav size="sm">
              <i-nav-item
                href="http://www.instagram.com/bockhaus"
                target="_blank"
                ><span>Instagram</span></i-nav-item
              >
              <i-nav-item :to="{ name: 'About' }"
                ><span>About</span></i-nav-item
              >
              <i-nav-item href="mailto:bockhaus.management@gmail.com"
                ><span>Email</span></i-nav-item
              >
            </i-nav>
          </div> -->
        </div>
      </i-column>
      <i-column>
        <router-view :key="$route.fullPath" />
      </i-column>
      <div
        class="
          _display-xs-block _display-md-none _display-lg-none _display-xl-none
          _fixed-bottom
          _background-white
          _padding-top-1-2 _padding-bottom-1-2 _padding-left-1-2
          bock--static-footer
        "
      >
        <div class="bock--static-footer-nav">
          <i-nav size="sm">
            <i-nav-item href="http://www.instagram.com/bockhaus" target="_blank"
              ><span>Instagram</span></i-nav-item
            >
            <i-nav-item :to="{ name: 'About' }"><span>About</span></i-nav-item>
            <i-nav-item href="mailto:bockhaus.management@gmail.com"
              ><span>Email</span></i-nav-item
            >
          </i-nav>
        </div>
      </div>
      <!-- modal -->
      <i-modal v-model="modal" size="md">
        <i-container>
          <div
            v-if="section == 'exhibition'"
            class="_display-flex _align-items-end"
          >
            <prismic-image
              v-if="exhibition.thumb"
              :field="exhibition.thumb"
              class="image bock--thumb"
            />

            <p class="_margin-bottom-0 _margin-left-1">
              <span
                v-if="activeExhibit.title"
                class="_font-weight-normal _margin-bottom-0 bock--title"
              >
                {{ activeExhibit.title }}
              </span>
              <span
                v-if="activeExhibit.gallery"
                class="
                  _margin-top-0 _margin-bottom-0
                  _display-block
                  _text-muted
                  bock--desc
                "
              >
                {{ activeExhibit.gallery }}
              </span>
              <span class="_display-block _text-muted bock--desc">
                <span v-if="activeExhibit.location">{{
                  activeExhibit.location
                }}</span
                ><span v-if="activeExhibit.year"
                  >, {{ activeExhibit.year }}</span
                >
              </span>
            </p>
          </div>
          <div
            v-else-if="section == 'exhibit'"
            class="_display-flex _align-items-end"
          >
            <prismic-image
              v-if="exhibit.thumb"
              :field="exhibit.thumb"
              class="image bock--thumb"
            />

            <p class="_margin-bottom-0 _margin-left-1">
              <span
                v-if="exhibit.title"
                class="
                  _font-weight-normal
                  _margin-bottom-0
                  _text-muted
                  bock--desc
                "
              >
                {{ exhibit.title }}
              </span>
              <span
                v-if="exhibit.medium"
                class="_display-block _text-muted bock--desc bock--trunc"
              >
                {{ exhibit.medium }}
              </span>
              <span
                v-if="exhibit.dimensionsIMP"
                class="_display-block _text-muted bock--desc bock--spec"
              >
                {{ exhibit.dimensionsIMP }}
              </span>
              <span
                v-if="exhibit.dimensionsMET"
                class="_display-block _text-muted bock--desc bock--spec"
              >
                {{ exhibit.dimensionsMET }}
              </span>
            </p>
          </div>
          <div
            v-else-if="section == 'mural'"
            class="_display-flex _align-items-end"
          >
            <prismic-image
              v-if="mural.thumb"
              :field="mural.thumb"
              class="image bock--thumb"
            />

            <p class="_margin-bottom-0 _margin-left-1">
              <span
                v-if="mural.title"
                class="
                  _font-weight-normal
                  _margin-bottom-0
                  _text-muted
                  bock--desc
                "
              >
                {{ mural.title }}
              </span>
              <span class="_display-block _text-muted bock--desc">
                <span v-if="mural.location">{{ mural.location }}</span
                ><span v-if="mural.year">, {{ mural.year }}</span>
              </span>
            </p>
          </div>
          <prismic-rich-text
            v-if="info !== null"
            :field="info"
            class="_text-muted _margin-top-0 small"
          />
          <i-button
            size="sm"
            link
            variant="primary"
            @click="modal = false"
            class="_padding-left-0"
            >Close</i-button
          >
        </i-container>
      </i-modal>
    </i-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import videojs from 'video.js';
import "video.js/dist/video-js.css";

export default {
  name: "App",
  data() {
    return {
      modal: false,
      info: null,
      section: null,
      videoOptions: {
        muted: true,
        autoplay: true,
				controls: false,
        loop: true,
				sources: [
					{
					src:
						"https://dgobh5p7ls4fa.cloudfront.net/b0f706b2-4353-41f6-aafa-37640c3311ec/hls/Anti-Promotional%20for%20'I'm%20Not%20Funded%20by%20the%20CIA'.m3u8",
					  type: "application/x-mpegURL"
					}
				]
      },
      player: null,
    };
  },
  computed: {
    ...mapGetters(["exhibition", "exhibit", "activeExhibit", "mural", "video"]),
    isExhibition() {
      if (this.$route.name === "Exhibition") {
        return true;
      } else {
        return false;
      }
    },
    isExhibit() {
      if (this.$route.name === "Exhibit") {
        return true;
      } else {
        return false;
      }
    },
    isMural() {
      if (this.$route.name === "Mural") {
        return true;
      } else {
        return false;
      }
    },
    isHome() {
      if (this.$route.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["setVideo"]),
    async getContent() {
      const response = await this.$prismic.client.getSingle("home");
      this.response = response;
      this.type = response.data.player;
      if (this.type === "YouTube") {
        this.url = `https://www.youtube.com/embed/${response.data.video_id}?controls=0&loop=1&autoplay=1&mute=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&modestbranding=1`;
        // this.url = `https://www.youtube.com/embed/${response.data.video_id}?controls=0`;
      } else {
        this.url = `https://player.vimeo.com/video/${response.data.video_id}?autoplay=1&&muted=1&loop=1&color=ffffff&byline=0`;
      }
      this.$store.dispatch("setVideo", this.url);
    },
    backToExhibition() {
      this.$router.push({
        name: "Exhibition",
        params: { uid: this.$route.params.uid },
      });
    },
    showExhibitionInfo(info) {
      this.info = info;
      this.modal = true;
      this.section = "exhibition";
    },
    showExhibitInfo(info) {
      this.info = info;
      this.modal = true;
      this.section = "exhibit";
    },
    showMuralInfo(info) {
      this.info = info;
      this.modal = true;
      this.section = "mural";
    },
  },
  watch: {
    isHome: function (val) {
      if (val) {
        this.getContent();
      }
    },
  },
  created() {
    this.$inkline.config.variant = "light";
    // if (this.isHome) {
    //   this.getContent();
    // }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

body.inkline.-dark {
  background: #fff !important;
}

.bock--ui-header {
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .bock--ui-header {
    width: 100%;
  }
}

.modal {
  width: calc(100% - 3rem) !important;
}

.bock--sidebar {
  justify-content: flex-start;
}

.bock--container {
  margin-top: 0 !important;
}

.bock--logo--nav {
  flex-direction: row;
}

.bock--block {
  line-height: 1.3 !important;
}

.bock--info {
  line-height: 1 !important;
}

.bock--footer-nav {
  visibility: hidden;
}

.bock--title {
  font-weight: 500 !important;
}

.bock--desc {
  font-size: 12px;
}

.bock--trunc {
  font-size: 10px;
}

.bock--spec {
  font-size: 9px;
}

.bock--thumb {
  width: 5rem;
}

.bock--small {
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
  a {
    text-decoration: underline;
  }
}

.bock--hover:hover {
  cursor: pointer !important;
  ._text-muted {
    color: $color-black !important;
  }
}

.bock--logo svg {
  width: 6rem;
}

.bock--light a svg:not(.bockgram) {
  fill: #fff !important;
}

.bock--fullscreen {
  z-index: 0;
}

@media screen and (min-width: 768px) {
  .bock--sidebar {
    height: 100vh;
    position: fixed;
    min-width: 16.66667%;
    max-width: 25%;
  }
  .bock--info {
  }
  .bock--container {
    margin-top: 6rem !important;
  }
  .bock--logo--nav {
    flex-direction: column;
  }
  .bock--logo {
    width: inherit;
  }
  .bock--footer-nav {
    visibility: visible;
  }
}

@media screen and (min-width: 992px) {
  .bock--sidebar {
    width: 16.66667%;
  }
}

.nav.bock--main-nav.-sm > .item,
.nav.bock--main-nav.-sm > .dropdown > .item {
  color: $color-gray-60 !important;
  fill: $color-gray-60 !important;
  padding: 0.1rem 0.8rem !important;
}

.nav.bock--main-nav.-sm > .item:hover,
.nav.bock--main-nav.-sm > .dropdown > .item:hover {
  color: $color-black !important;
  fill: $color-black !important;
}

.bock--light .nav.bock--main-nav.-sm > .item:hover,
.bock--light .nav.bock--main-nav.-sm > .dropdown > .item:hover {
  color: #fff !important;
  fill: #fff !important;
}

.nav.bock--main-nav.-sm > .item.router-link-active,
.nav.bock--main-nav.-sm > .dropdown > .item.router-link-active {
  color: $color-black !important;
  fill: $color-black !important;
  font-size: 1rem !important;
  font-weight: 500;
}

.bock--footer-nav .nav.-sm > .item,
.bock--footer-nav .nav.-sm > .dropdown > .item,
.bock--static-footer-nav .nav.-sm > .item,
.bock--static-footer-nav .nav.-sm > .dropdown > .item {
  padding: 0.1rem 0.3rem !important;
  font-size: 10px;
}

.bock--exhibit-nav .nav.-sm > .item,
.bock--exhibit-nav .nav.-sm > .dropdown > .item {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .bock--image-square {
    max-height: 75vh;
    max-width: 75vw;
  }
  .bock--image-wide {
    max-width: 75vw;
  }
  .bock--image-tall {
    max-height: 75vh;
  }
}

.bock--exhibit-container {
  height: inherit !important;
}

@media screen and (min-width: 768px) {
  .bock--exhibit-container {
    height: 100vh !important;
  }
}

.exhibit:hover {
  cursor: pointer;
}

.modal-overlay {
  overflow-y: auto !important;
}

.modal {
  max-width: 75% !important;
  max-height: 90vh !important;
}

.zoom {
  .modal-overlay {
    // overflow: auto !important;
  }
  .modal {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.bock--background {
  background-color: #000 !important;
}

.b-top {
  top: 0;
}

// svg.bockgram {
//   fill: $color-gray-60 !important;
// }
</style>
