import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/work",
    name: "Exhibitions",
    component: () =>
      import(/* webpackChunkName: "exhibitions" */ "../views/Exhibitions.vue"),
  },
  {
    path: "/work/:uid",
    name: "Exhibition",
    component: () =>
      import(/* webpackChunkName: "exhibition" */ "../views/Exhibition.vue"),
  },
  {
    path: "/work/:uid/:id",
    name: "Exhibit",
    component: () =>
      import(/* webpackChunkName: "exhibit" */ "../views/Exhibit.vue"),
  },
  {
    path: "/videos",
    name: "Videos",
    component: () =>
      import(/* webpackChunkName: "videos" */ "../views/Videos.vue"),
  },
  {
    path: "/videos/:id",
    name: "Video",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/Video.vue"),
  },
  {
    path: "/murals",
    name: "Murals",
    component: () =>
      import(/* webpackChunkName: "murals" */ "../views/Murals.vue"),
  },
  {
    path: "/murals/:id",
    name: "Mural",
    component: () =>
      import(/* webpackChunkName: "mural" */ "../views/Mural.vue"),
  },
  {
    path: "/press",
    name: "Press",
    component: () =>
      import(
        /* webpackChunkName: "publications" */ "../views/Publications.vue"
      ),
  },
  {
    path: "/cv",
    name: "CV",
    component: () =>
      import(/* webpackChunkName: "resume" */ "../views/Resume.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "*",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
    // document.getElementById('app').scrollIntoView();
    // console.log("scrollBehavior");
  },
});

export default router;
