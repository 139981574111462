<template>
  <i-container fluid class="bock--background">
    <i-row>
      <i-column
        class="_display-flex _justify-content-center _align-items-center"
      >
        <!-- <div class="_embed _embed-16by9">
          <iframe
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            width="1920"
            height="1080"
            type="text/html"
            :src="url"
            allowfullscreen
          ></iframe>
        </div> -->
        <!-- <pre
          v-if="response"
          id="app"
          v-html="JSON.stringify(response, null, 2)"
        ></pre>-->
      </i-column>
    </i-row>
  </i-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      response: null,
      type: null,
      url: null,
    };
  },
  components: {
    // HelloWorld,
  },
  methods: {
    ...mapActions(["setVideo"]),
    async getContent() {
      const response = await this.$prismic.client.getSingle("home");
      this.response = response;
      this.type = response.data.player;
      if (this.type === "YouTube") {
        this.url = `https://www.youtube.com/embed/${response.data.video_id}?playlist=${response.data.video_id}&loop=1&autoplay=1&mute=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&modestbranding`;
      } else {
        this.url = `https://player.vimeo.com/video/${response.data.video_id}?autoplay=1&&muted=1&loop=1&color=ffffff&byline=0`;
      }
      this.$store.dispatch("setVideo", this.url);
    },
  },
  created() {
    // console.log("created");
    this.getContent();
  },
};
</script>

<style lang="scss">
.loader {
  bottom: 50%;
}
</style>
